// Browser check variables
// If you support IE, our recommendation is that you sign-in using Redirect APIs

import { AccountInfo, LogLevel, PublicClientApplication } from "@azure/msal-browser";
import { AxiosRequestConfig, AxiosRequestHeaders } from "axios";
//import { msalInstance } from "index";
import { AxiosFetchSelfServeApi } from "./AxiosFetch";
import { TelemetryService } from "./TelemetryService";

// If you as a developer are testing using Edge InPrivate mode, please add "isEdge" to the if check
const ua = window.navigator.userAgent;
const msie = ua.indexOf("MSIE ");
const msie11 = ua.indexOf("Trident/");
const msedge = ua.indexOf("Edge/");
const firefox = ua.indexOf("Firefox");
const isIE = msie > 0 || msie11 > 0;
const isEdge = msedge > 0;
const isFirefox = firefox > 0; // Only needed if you need to support the redirect flow in Firefox incognito

export const msalConfig = {
	auth: {
		clientId: "306c24dc-2a9d-4112-a221-b7d664a3c679",
		authority: "https://login.microsoftonline.com/72f988bf-86f1-41af-91ab-2d7cd011db47",
		redirectUri: '/',
		postLogOutRedirectUri: '/',
	},
	cache: {
		cacheLocation: "localStorage",
		storeAuthStateInCookie: isIE || isEdge || isFirefox,
	},
	system: {
		allowNativeBroker: false, // Disables WAM Broker
		loggerOptions: {
			loggerCallback: (level: LogLevel, message: string, containsPii: boolean) => {
				if (containsPii) {
					return;
				}
				switch (level) {
					case LogLevel.Error:
						TelemetryService.trackException(message, { level });
						return;
					case LogLevel.Info:
						TelemetryService.trackTrace(message, { level });
						return;
					case LogLevel.Verbose:
						TelemetryService.trackTrace(message, { level });
						return;
					case LogLevel.Warning:
						TelemetryService.trackTrace(message, { level });
						return;
					default:
						return;
				}
			},
		},
	},
};

export const msalInstance = new PublicClientApplication(msalConfig);

export const LogOut = (): void => {
	msalInstance.logoutRedirect();
};

// Add here scopes for id token to be used at MS Identity Platform endpoints.
export const loginRequest = {
	scopes: ["306c24dc-2a9d-4112-a221-b7d664a3c679/.default"]
};

export function getAccount() {
	const defaultAccount = {
		username: 'user',
		name: 'user',
	} as AccountInfo;

	let allAccounts = msalInstance.getAllAccounts();
	let activeAccount = msalInstance.getActiveAccount()
		? msalInstance.getActiveAccount()
		: allAccounts.length > 0
			? allAccounts[0]
			: null;
	if (activeAccount === null) {
		return defaultAccount;
	}
	return activeAccount;
}

export async function getToken(request = loginRequest) {
	const account = getAccount();

	if (!account) {
		return null;
	}
	const response = await msalInstance.acquireTokenSilent({
		...request,
		account: account
	});
	return response.accessToken;
}

export async function getHeader() {
	const accessToken = await getToken(loginRequest);
	const headers = {} as AxiosRequestHeaders;
	const bearer = `Bearer ${ accessToken }`;
	headers["Authorization"] = bearer;
	return headers;
}

export const msalScenarioHealthApiFetch = {
	get: async (url: string, options: AxiosRequestConfig) => {
		const headers = await getHeader();
		return AxiosFetchSelfServeApi.get(url, { ...options, headers: headers })
	},
	post: async (url: string, data: any, options: AxiosRequestConfig) => {
		const headers = await getHeader();
		return AxiosFetchSelfServeApi.post(url, data, { ...options, headers: headers })
	}
};

// admin upn
export const AdminUsers = [
	// Managers
	'jam@microsoft.com',
	'vijanu@microsoft.com',
	// PMs
	'shriramn@microsoft.com',
	'dagrawal@microsoft.com',
	'robpie@microsoft.com',
	// Devs
	'sivakot@microsoft.com',
	'prvora@microsoft.com',
	'stellaren@microsoft.com'
];

export function isAdminUser() {
	const account = getAccount();
	const username = account.username ?? '';
	return AdminUsers.includes(username);
}

export const isDevelopment = (process.env.REACT_APP_Env ?? '').toLowerCase() === '_development';

export function showDevAdminItems() {
	return isAdminUser() && isDevelopment
};
